
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');


html,
body,
#root,
.app{
  height:100%;
  width:100%;
  font-family:"Inter",sans-serif;
  
}
/* Dashboard.css */

/* Common styles for both small and large screens */
.Box-container {
margin: 1.5rem 3.5rem;
margin-left: 20px;
}

/* .FlexBetween {
/* Your existing styles for FlexBetween component */
/* }  */

/* .Header {
/* Your existing styles for Header component */
/* }  */

/* Styles for small screens */
@media (max-width: 600px) {
.Box-container {
  margin: 1rem;
}

.Grid-container {
  grid-template-columns: 1fr;
  gap: 10px;
}

/* .StatBox {
  /* Adjust styles for StatBox on small screens */
} 

.DataGrid {
  margin-top: 10px;
}
/* } */

/* Styles for medium to large screens */
@media (min-width: 601px) and (max-width: 1280px) {
.Box-container {
  margin: 1.5rem 3.5rem;
}

.Grid-container {
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
}

/* .StatBox {
  /* Adjust styles for StatBox on medium screens */
} 

.DataGrid {
  margin-top: -60px;
}
/* } */

